.hidden {
  display: none;
}

.dialog-backdrop.active {
  display: block;
}

.dialog-backdrop {
  z-index: 10000 !important;
}

.dialog__content {
  padding: 20px 50px;
}

.dialog-btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 0;
  outline: 0;
  background: 0 0;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: #212121;
  color: #fff;
  padding: 0;
  border-radius:10px;

  &:after {
    content: "+";
    transform: rotate(45deg);
    font-size: 2.5rem;
    position: absolute;
    top: -4px;
    left: 15px;
    transition: all .3s ease-out;
  }
}

.default_dialog {
  color: #000;
  padding-bottom: 20px;
border-radius:10px;
  font-size: 14px;

  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  max-width: 840px;
  margin-top: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12),
  0 15px 12px rgba(0, 0, 0, 0.22);
  position: absolute;
  top: 50%;
  left: 50vw;

  /* move to the middle of the screen (assumes relative parent is the body/viewport) */
  transform: translate(-50%, -50%);

  .dialog_content {
    padding: 15px 20px;
    max-height: 88vh;
    overflow: auto;

    h3 {
      font-size: 18px;
    }
  }

  @media (max-height: 700px), (max-width: 700px) {
    font-size: 12px;
    .form-checkbox label {

      font-size: 12px;
    }
    .m-b-20 {
      margin-bottom: 10px !important;
    }
  }

  @media screen and (min-width: 640px) {

    .modal__container {
      padding: 30px 45px 20px 45px;

      h3 {
        font-size: 20px;
      }
    }
    .btn--close:not(.btn) {
      position: absolute;
      top: 30px;
      right: 40px;

    }
  }

  @media(min-width: $grid-breakpoints-md) {
    h2,
    h4 {
      font-size: 20px;

    }
    .btn--close:not(.btn) {
      top: 20px;
      right: 15px;
    }

    h2,
    h4 {
      font-weight: 700;
      margin-bottom: 20px;

      small {
        display: block;
        margin-top: 15px;
        font-weight: 400;
      }
    }
  }

  @media(max-width: $grid-breakpoints-sm) {

    h2,
    h4 {
      font-size: 18px;
    }

  }

  .owl-carousel__btn {
    opacity: 1;
  }
}


/* native <dialog> element uses the ::backdrop pseudo-element */

/* dialog::backdrop, */
.dialog-backdrop {
  display: none;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
}


.dialog-backdrop {
  background: rgba(0, 0, 0, 0.6);
}


.dialog-backdrop.active {
  display: block;
}

.no-scroll {
  overflow-y: auto !important;
}