
.headerControls {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: 14px;
  gap: 0px;
  width: 100%;

}


.headerControls__link {
  display: inline-flex;
  width: 50px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  position: relative;


  svg {
    transition: all 0.3s ease-in-out;
    stroke: $txt !important;
    fill: transparent;
  }

  @media(hover: hover) {
    &:hover {
      svg {
        stroke: $main !important;
      }
    }
  }
}

.headerControls__heart {
  svg {
    fill: transparent;
  }

  &.active {
    svg {
      fill: $main;
      stroke: $main !important;
    }
  }
}

.header__cartAmount {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  font-weight: 700;
  min-width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  display: block;
  color: #fff;
  background: $main;
}

.header__cartAmount {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.headerControls__link.is-active {
  .header__cartAmount {
    opacity: 1;
  }
}

@media(hover: hover) {
  .login__wrapper:hover .login__list {
    max-height: 500px;
  }
}


.login__list {

  width: auto;
  right: 0;
  min-width: 220px;
  position: absolute;
  background: #fff;
  box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
  top: 90%;
  z-index: 100;
  padding: 0 15px;
  max-height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 10px;

  &.open {
    max-height: 500px;
  }

  a {
    padding: 10px 0;
    display: block;
    color: $txt;

  }

  span {
    display: block;
    font-size: 12px;
    padding: 15px 0;
    text-align: center;
  }

  li:not(:last-child) {
    border-bottom: 1px solid $line;
  }

  li {
    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .login__list--2 {
    li {
      &:first-child {
        margin-top: 5px;
      }

      &:last-child {
        margin-bottom: 5px;
      }
    }
  }
}

.login__wrapper {
  position: relative;

}

.headerControls__search {
  width: 100%;
}

.headerForm {
  display: flex;
  position: relative;
  width: 100%;
  padding-right: 50px;

  button {
    min-width: 40px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../img/ico-search.svg");
    background-size: 16px;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  ::placeholder {
    color: #AAAAAA;
  }

  input {
    border: none;
    background: $bg-grey;
    border-radius: 20px;
    padding-left: 40px;
    padding: 7px 0 7px 40px;
    width: 100%;

  }
}

#mainSearchBtn {
  display: none;
}

@media(max-width: $grid-breakpoints-sm) {
  #mainSearchBtn {
    display: inline-flex;
  }
  .headerControls__search {
    width: initial;
  }
  .headerForm {
    position: fixed;
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      min-width: 60px;
      height: 60px;
      top: 22px;
    }

    &.open {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 20;
      height: 100px;
      padding: 20px 4vw;
      background: $bg-grey;
      -webkit-animation-name: fixedMenuDown;
      animation-name: fixedMenuDown;
      -webkit-animation-duration: .5s;
      animation-duration: .5s;
      box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);

    }


    .headerForm__close {
      min-width: 48px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $main;
      font-size: 40px;

      &:before {
        content: "+";
        transform: rotate(45deg);
        line-height: 1;
      }

      &:hover {
        color: $red;
      }
    }


    input {
      border: none;
      width: 100%;
      border-bottom: 1px solid $line;
      padding-left: 30px;
      font-size: 16px;
      padding-top: 14px;
      padding-bottom: 9px;
      background: transparent;

      &:focus {
        outline: none;
      }
    }


  }
}

.nav-mobile-close,
.search-mobile-close {
  display: none;
  position: fixed;
  z-index: -10;
}

.search-mobile-close.open {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  border: none;
  top: 80px;
  left: 0;
  z-index: 12;
}
