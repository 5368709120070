@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 300;
    src: url(fonts/ProximaNovaT-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 400;
    src: url(fonts/ProximaNova-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 500;
    src: url(fonts/ProximaNova-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 700;
    src: url(fonts/ProximaNova-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'ProximaNova';
    font-style: normal;
    font-weight: 900;
    src: url(fonts/ProximaNova-Black.ttf) format('truetype');
}