.breadcrumbs {
  display: flex;
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;

  &.breadcrumbs--border {
    border-bottom: 1px solid $line;
  }

  span,
  a {
    font-size: 13px;
    color: #807b75;
    display: inline-block;
    padding: 7px 0;
    line-height: 1;
    font-weight: 300;

    &:before {
      margin-right: 10px;
      color: #807b75;
      content: "/";
    }
  }

  li:first-child {
    a:before {
      content: none;
    }
  }

  a {
    margin-right: 10px;

    &:focus,
    &:hover {
      outline: none;
      color: $main2;
    }
  }

  @media(max-width: $grid-breakpoints-sm) {
    span,
    a {
      font-size: 12px;
      padding:5px 0;
      &:before{
        margin-right: 5px;
      }
    }
    a{
      margin-right: 5px;
    }
  }
}


.bc__wrapper{
  background:$bg-grey;
  position:relative;
  z-index:10;
  margin-bottom: 40px;
  @media(max-width: $grid-breakpoints-sm) {
    margin-bottom: 20px;
    .breadcrumbs {
      padding: 5px 0;
    }
  }
}

@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

.menu-is-fixed {
  padding-top: 130px;
}



.header {
  background: $txt;
  position: relative;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;

  .btn{
    padding-left:15px;
    padding-right:15px;
  }

  &.fixed-header-scroll {
    position: fixed;
    top: 0;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    .header__wrapper {
      min-height: 50px;
    }

    .btn{
      padding-top:10px;
      padding-bottom:10px;
    }
  }


}


.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  min-height: 100px;
}

.header__top {
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  width: 100%;
  height: 60px;
  gap: 60px;
}

.header__left {
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 20px;
}

.header__right {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.lang__list {
  display: flex;

  a {
    display: block;
    padding: 0 10px;
    font-size: $font12;
    font-weight: 300;
    line-height: 2;
    color: $main;

    &.active {
      font-weight: 700;
    }

    @media(hover: hover) {
      &:hover {
        color: #08ABED;
      }
    }
  }

}


.header__logo {
  max-width: 160px;
  flex: 0 0 160px;

  img {
    width: 100%;
  }
}

.link--add {
  background-image: url("../img/ico-add.svg");
  background-repeat: no-repeat;
  background-position: center;
}


.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: $font20;
  left: 60px;
  line-height: 50px;

  strong {
    margin-left: -20px;
  }


  span {
    width: 26px;
    height: 2px;
    background: $txt;
    display: block;
    position: absolute;
    left: 12px;
    top: 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: center;

    &:nth-child(2) {
      top: 25px;
    }

    &:nth-child(3) {
      top: 35px;
    }

  }

  &.open span {
    &:first-child {
      transform: rotate(45deg);
      top: 24px;
    }

    &:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 24px;
      transform: rotate(-45deg);
    }
  }

}


.header__wrapper {
  //background: $main;
}

.header__bottom {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  width: 100%;
  padding-top: 14px;

}



.page__header {
  position: relative;
  z-index: 1;
  min-height: 300px;
  padding: 8vw;
  display: flex;
  align-items: center;
  border-top: 1px solid $main;
  background: #fff;
  @media(max-width: $grid-breakpoints-sm) {
    padding: 8vw 4vw;
    min-height: 180px;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(246, 244, 242, 0.8), transparent, transparent, rgba(246, 244, 242, 0.8));
    z-index: -1;
    position: absolute;
  }


  .container {
    position: relative;
    z-index: 1;
    text-align: center;
  }

  h1,
  h2 {
    font-size: 43px;
    font-weight: 900;
    margin-bottom: 0;
    margin-top: 10px;

    @media(max-width: $grid-breakpoints-sm) {
      font-size: $font34;
    }

  }

  .pageHeader__img1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    opacity: 0.39;
  }

  .pageHeader__img2 {
    position: absolute;
    bottom: -8.5vw;
    right: 0;
    width: 40vw;
    height: auto;
    z-index: 0;
  }

}


.page__header2 {
  background: transparent;
  min-height: 0;
  padding: 0;

  .pageHeader__img2 {
    bottom: -2.1vw;
    right: 4vw;
    width: 10vw;
    @media(max-width: $grid-breakpoints-sm) {
      bottom: -4vw;
      right: 0vw;
      width: 20vw;
    }
  }
}


header > ul > li {
  position: relative;
}


.nav__wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.nav {
  width: 100%;

  a {
    color: $white;
    font-size: 16px;
    font-weight: 700;
    padding: 17px 12px;
    display: inline-block;
    font-family: $font2-family-name;
    transition-property: color;
    font-weight: 700;
    text-transform: uppercase;


    @media(hover: hover) {
      &:hover {
        color: $main2;
      }
    }
  }


}


.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}


.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

.menu-open {
  .menu-right__wrapper {
    z-index: 0;
  }
}


.nav-subpage {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $main-light2;

  ul {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
  }

  a {
    color: $main;
    position: relative;
    padding: 5px 0;
    font-size: $font18;
    font-weight: 700;
    display: block;

    &:before {
      content: "•  ";

    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 4px;
      left: 0;
      width: 0;
      transition: all 0.3s ease-in-out;
      background: $main;
    }

    &.active {
      color: $main2;

      &:after {
        background: $main2;
        width: 100%;
      }
    }

    @media(hover: hover) {
      &:hover {
        color: $main2;

        &:after {
          width: 100%;
        }
      }
    }
  }

}


.menu__item--has-children {
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    position: absolute;
    right: 10px;
    top: 22px;
    transform: rotate(-45deg);
  }
}

@media(min-width: $grid-breakpoints-xl) {

  .header {

    &.fixed-header-scroll {
      .header__bottom {
        display: none;
      }

      .header__top {
        align-items: center;
        height: 50px;
      }

      .headerForm input {
        background: #fff;
        border: 1px solid #AAAAAA;
      }
    }
  }


  .nav a.active {
    color: $main2;
  }

  .menu__item--has-children {
    position: relative;

    > a {
      padding-right: 25px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .nav__level-2 {
      padding: 0;
      position: absolute;
      width: 200px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      left: 0px;
      opacity: 0;
      z-index: -1000;
      top: 100%;
      height: 0;
      overflow: hidden;
      background: $black;
      box-shadow: 0px 10px 10px 0 rgb(0, 0, 0, 0.25);

      > ul {
        padding: 0;
      }

      li {
        border-top: none;

        a {
          font-size: 16px;
          text-transform: initial;
          color: $white;
          display: block;
          padding: 7px 10px 7px 15px;
          position: relative;
          font-weight: 400;
          white-space: initial;
        }
      }

      a {
        &:hover {

          color: $main2;

          &:after {
            background: $main;
          }
        }

        &:focus-visible {
          outline-color: $main2;
          outline-offset: -2px;
        }
      }
    }


    &.open .nav__level-2 {
      z-index: 30;
      height: initial;
      opacity: 1;
      overflow: visible;
      transition: opacity 0.3s ease-in-out;

    }
  }

  .menu__item--has-children:hover {
      .nav__level-2 {
      z-index: 30;
      height: initial;
      opacity: 1;
      overflow: visible;
      transition: opacity 0.3s ease-in-out;
    }
  }

}


@media(max-width: $grid-breakpoints-xl) {

  .header {

    &.fixed-header-scroll {
      .header__top {
        align-items: center;
        height: 50px;
      }

      .header__wrapper {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .headerForm input {
        background: #fff;
        border: 1px solid #AAAAAA;
      }

      .nav-toggle {
        top: 0px;
      }

      .nav__level-2 {
        top: 50px;
        height: calc(100vh - 50px);
      }

      .activeLi > a {
        top: 50px;
      }
    }
  }


  .header {
    transition: background 0s ease-out 0.3s;
  }

  .header__top {
    padding-left: 60px;
    position: relative;
    align-items: center;
    gap: 20px;
  }

  .headerForm {
    padding-right: 20px;
  }


  .header__wrapper {
    flex-wrap: nowrap;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    width: 100%;

  }
  .header__right {
    gap: 0;
  }

  .header__bottom {
    position: static;
    flex: 0 0 0;
  }


  .btn__login {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    padding: 0;
    text-indent: -1000px;
    overflow: hidden;
    height: 48px;
    background: transparent url("../img/user.svg") center no-repeat;
    background-size: 15px;
  }

  .menu__item--has-children {
    position: relative;
    height: 52px;

    > a {
      width: 100% !important;
      position: relative;
      z-index: 1;

    }

    &:after {
      width: 12px;
      height: 12px;
      top: 18px;
      transform: rotate(-135deg);
      z-index: 0;
    }
  }

  .nav__level-1 > li {
    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    > a {
      padding: 12px 0;
      font-size: 19px;
    }
  }
  .headerForm {
    width: 100%;
  }
  .nav .nav__wrapper {
    // display: none;
    position: absolute;
    top: 100%;
    display: block;
    background: $bg-grey;
    width: 100%;
    left: -100%;
    padding: 0 4vw 80px 4vw;
    height: calc(100vh - 80px);
    margin-top: 0;
    transition: all 0.3s ease-out;


    &.open {
      left: 0;
      box-shadow: -2px 5px 5px 0 rgba(0, 0, 0, 0.2);
    }

    li {
      width: 100%;
    }

    > li:last-child {
      margin-bottom: 25px;
    }
  }

  .activeLi {

    > a {
      position: fixed;
      top: 80px;
      height: 60px;
      z-index: 40;
      left: 0;
      padding-left: 12vw !important;
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      border-bottom: 2px solid #707070;
      color: $txt !important;
      background-color: $bg-grey;

      background-image: url("../img/left-menu-arrow.svg");
      background-position: left 20px center;
      background-repeat: no-repeat;

    }
  }

  .nav__level-2 {
    position: fixed;

    top: 80px;
    left: -100vw;
    transition: all 0.3s ease-out;
    background: $bg-grey;
    padding: 60px 4vw 80px 4vw;
    height: calc(100vh - 80px);
    overflow: auto;
    width: 100vw;

    ul {
      padding-left: 8vw;
    }

    .so {
      width: calc(100% - 80px);
      height: 0;
      padding-top: 95%;
      min-width: initial;
      max-width: initial;
      margin-left: 40px;
      margin-top: 20px;
    }

    .so__txt {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }

    a {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 0;
    }

    &.m-open {
      left: 0;
      z-index: 30;
    }
  }


  .nav {
    width: 100%;
  }

  .nav-toggle {
    display: block;
    position: absolute;
    top: 16px;
    margin-left: -6px;
  }

  .nav__wrapper.nav__level-1 ul.open {
    max-height: 600px;
    overflow: auto;
  }

  .nav {
    margin-left: auto;
    flex: 0 0 50px;
    max-width: 50px;
  }

  .headerControls__link {
    width: 36px;
  }

  .header.fixed-header-scroll {

    .header__bottom {
      margin-left: auto;
      justify-content: flex-end;
    }


    .header__left {
      padding-top: 0;
      padding-bottom: 0;
    }

    .header__social {
      display: none;
    }
  }
}


@media(max-width: $grid-breakpoints-md) {
  .nav-toggle {
    left: 20px;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .header__logo {
    max-width: 120px;
    flex: 0 0 120px;
  }
  .header__top {
    padding-left: 50px;
  }
  .headerControls {
    justify-content: flex-end;
  }
}


@media(max-width: 370px){
  .header__top {
    padding-left: 40px;
    gap:15px;
  }
  .header__logo {
    max-width: calc(100% - 150px);
    flex: 0 0 120px;
  }
}



.header__cart-box {
  position: fixed;
  right: -420px;
  top: 0;
  background: $bg-grey;
  transition: right 0.3s ease-in-out;
  z-index: 90;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 40px 20px;
  width: 400px;
  max-width: 90%;
  height: 100vh;

  .alert-info, .alert-danger {
    margin-top: 10px;
    margin-bottom: 0;
    //font-size: $font10;
    padding: 7px 5px;
    margin-bottom: 5px;
  }

  .alert-info {
    color: $main2 !important;

    img {
      margin-right: 5px;
      width: 30px;
      height: auto;
    }
  }

  .cart-free-shipping-active {


    //font-weight: 700;
  }

  &.open {
    right: 0;
    z-index: 100;
  }

  .alert-success {
    display: none;
  }

  .btn--close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    background-image: url("../img/zamknij.svg");
    background-size: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
  }


  h2,
  h4 {
    font-size: $font24;
    font-weight: 700;
    color: $txt;
  }

  .link {
    font-size: $font12;
  }


  .btn-close--magenta {
    top: 8px;
    right: 0px;
    width: 20px;
    height: 20px;
    display: block;
    background-size: 10px;
    opacity: 0;
  }

  li:hover {

    border-color: $main2;

    .btn-close--magenta {
      opacity: 1;
    }


  }

  .header__cart-wrapper {
    overflow: auto;
    max-height: calc(100% - 330px);
  }

  @media(max-width: $grid-breakpoints-sm) {
    padding-bottom: 50px;
    width: 100%;
    right: -100%;
  }
}


.header__cart-wrapper {


  li {
    position: relative;
    padding: 12px 0;
    border-bottom: 1px solid $line;

  }

}

.header__cart-sum {

  margin-top: 15px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: $font11;
    color: $txt-light;

    &:first-child {
      font-weight: 700;
      color: #000;
      font-size: $font16;
    }

  }

}

.btn--gtc {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: $font16;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn-close-cart2 {
  font-size: $font12;
  color: $txt;
  text-decoration: underline;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: none;
  border: none;
  margin-top: 10px;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}


.header__cart-item {
  display: flex;
  max-width: 100%;

  .qty {
    display: flex;
    align-items: center;

    input {
      font-size: 16px;
      max-width: 40px;
      height: 32px;
      border: 1px soldi $line;
      padding: 0;
      text-align: center;
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;

    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    button {
      height: 32px;
      width: 24px;
      border: none;
      background: none;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  figure {
    margin-top: 0px;
    height: 80px;
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    margin-right: 10px;
  }

  h3 {
    font-size: $font16;
    font-weight: 700;
    padding-right: 30px;
    margin-bottom: 10px;
    margin-top: auto;
    transition: all 0.1s ease-in-out;
    color: $txt;

    a {
      color: $txt;
    }
  }

  p {


    span {
      color: $txt-light;
    }
  }

  &:hover {
    h3 {
      color: $main;
    }
  }
}

.header__cart-item-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

}

.header__cart-item-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $txt;
  font-size: 16px;

  span {
    color: $txt-dark;
  }
}

.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

#header-cart-noRabat {
  color: $txt-dark;
  text-decoration: line-through;
  font-weight: 400;
  margin-left: auto;
  margin-right: 7px;
  font-size: 14px;
}

#header-cart-sum {
  font-size: 20px;
}



.is-active + .header__cart-box {
  .cart--empty {
    display: none !important;
  }
}

#headerControls__cartBtn:not(.is-active) + .header__cart-box {
  .alert, .header__cart-sum, .btn--go-cart {
    display: none !important;
  }

  .cart--empty {
    display: block !important;
  }
}
