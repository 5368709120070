.go-top {
  position: absolute;
  top: -40px;
  right: 0;
  display: inline-block;
  background-image: url("../img/ico_top.svg");
  background-repeat: no-repeat;
  background-position: center top;
  border: none;
  background-color: transparent;
  padding-top: 75px;
  font-weight: 700;

  span {
    font-size: $font12;
    color: #AAAAAA;
    text-transform: uppercase;
    transform: rotate(-90deg);
    display: block;
    padding-right: 65px;
  }
}



.footer {
  position: relative;
  background: $black;
  z-index: 1;
  line-height: 1.8;
  color:$white;
}


.footer__center {
  padding-top: 60px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  position:relative;

}


.footer__col {
  h3 {
    font-size: 16px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
}

.footer__list {
  list-style: none;
  padding-left: 0;

  a {
    color: $main2;
    font-size: 14px;
    display: inline-block;
    padding: 4px 0;
    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }
  }
}


.footer__bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  z-index: 10;
  font-size: 12px;
  background:$main2;

  .container {
  text-align: center;
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .footer__col h3 {
    margin-bottom: 15px;
  }
  .footer .footer__col{
    margin-bottom:50px;
  }



}


